import React, { FC, useState, ChangeEvent, useEffect, FormEvent } from "react";
import { IconButton } from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import cn from "classnames";
import style from "./style.module.scss";
import Switch from "../../../../newUI/Switch/Switch";
import SearchField from "../SearchField";
import DashboardSkeleton from "../Skeleton";
import ProjectsList from "./Projects/ProjectsList";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getDashboardDestinations,
  setShowFulfilledSections,
} from "../../../../../app/feature/dashboard/destinations";
import {
  getDashboardProjects,
  setFulfilled,
  setPreQuery,
} from "../../../../../app/feature/dashboard/projects";
import { useIsMobile } from "../../../../hooks/useIsMobile";

const Projects: FC = () => {
  const dispatch = useAppDispatch();

  const isTablette = useIsMobile(1600);
  const isMobile = useIsMobile(1100);

  const [query, setQuery] = useState<string>("");
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);

  const { pending, preQuery, fulfilled } = useAppSelector(getDashboardProjects);
  const { userType } = useAppSelector(getDashboardDestinations);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setQuery(preQuery);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const queryValue = event.target.value;

    dispatch(setPreQuery(queryValue));

    if (!queryValue.length) {
      setQuery("");
    }
  };

  const handleToggleFulfilled = () => {
    dispatch(setFulfilled(!fulfilled));
    dispatch(setShowFulfilledSections(!fulfilled));
  };

  useEffect(() => {
    setQuery(preQuery);
  }, []);

  useEffect(() => {
    if (fulfilled) {
      dispatch(setFulfilled(false));
      dispatch(setShowFulfilledSections(false));
    }

    if (query.length) {
      dispatch(setPreQuery(""));
    }
  }, [userType]);

  const searchPending = pending && preQuery.length;

  const skeletonCount = isTablette ? 7 : 8;

  return (
    <div className={cn(style.wrapper, style.projectsPane)}>
      <form
        onSubmit={handleSubmit}
        className={cn(style.header, style.projectHeader)}
      >
        {isMobile ? (
          <>
            <h3>Проект</h3>
            <div className={style.mobileHeader}>
              <p>Выполнено</p>
              <Switch checked={fulfilled} onChange={handleToggleFulfilled} />
              <IconButton
                onClick={() => setShowSearchBar(!showSearchBar)}
                className={style.mobileSearchBar}
              >
                <Search style={{ color: "#9DAFBD" }} />
              </IconButton>
              <a
                className={style.addProject}
                href={`/project/create?userTypeId=${userType}`}
                target="blank"
              >
                <IconButton className={style.roundButton}>
                  <Add />
                </IconButton>
              </a>
            </div>
            {showSearchBar && (
              <SearchField
                pending={searchPending}
                className={style.searchBar}
                value={preQuery}
                onChange={handleChange}
                handleSubmit={handleSubmit}
                mobile
              />
            )}
          </>
        ) : (
          <>
            <h3>Проект</h3>
            <p>Выполнено</p>
            <Switch
              className={style.fulfilledSwitch}
              checked={fulfilled}
              onChange={handleToggleFulfilled}
            />
            <SearchField
              pending={searchPending}
              className={style.searchBar}
              value={preQuery}
              onChange={handleChange}
              handleSubmit={handleSubmit}
            />
            <a
              className={style.addProject}
              href={`/project/create?userTypeId=${userType}`}
              target="blank"
            >
              <IconButton className={style.roundButton}>
                <Add />
              </IconButton>
            </a>
          </>
        )}
      </form>
      {userType ? (
        <ProjectsList fulfilled={fulfilled} query={query} />
      ) : (
        <DashboardSkeleton count={skeletonCount} />
      )}
    </div>
  );
};
export default Projects;
